import React, { Fragment, Suspense, lazy, useEffect } from "react";
import { Grid } from "@material-ui/core";
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { getTheme } from "./SharedComponents/ThemeComponent";
import { useSelector, useDispatch } from "react-redux";
import { selectDarkMode, toggleDarkMode } from "./Redux/Reducers/darkModeSlice";
// MUI Imports
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";

//Components Import
import Authentication from "./Core/Authentication";
import Routes from "./Core/Routes/Routes";
import LoaderComponent from "./Core/Loader/LoaderComponent";
import SidebarComponent from "./Components/SidebarComponent/SidebarComponent";
import HeaderBar from "./Components/HeaderBar/HeaderBar";
import { getRole } from "./Core/utils/auth";
import ExerciseTracker from "./Components/Tracker/ExerciseTracker";
import NewExerciseTracker from "./Components/NewTracker/NewExerciseTracker";
import ExerciseTrackerCatalog from "./Components/Tracker/ExerciseTrackerCatalog";
import MathWorkSheet from "./Components/Courses/Maths";
import Sections from "./Components/Courses/Sections/Index";
import NativeComponent from "./Components/NativeComponents/Index";
import { showSidebarStatus } from "./Redux/Reducers/sidebarSlice";
import AppHeaderBar from "./Components/HeaderBar/AppHeaderBar";
import AppPathCreationContainer from "./Components/HomePage/LearningPath/AppPathCreationContainer";

//Lazy Loading Imports
const Login = lazy(() => import("./Components/Login/Login"));
const MentorDashboard = lazy(() =>
  import("./Components/Mentor/MentorDashboard")
);
const DetailedInsight = lazy(() =>
  import("./Components/Evaluation/Insights/DetailedInsights")
);
const EvaluationResults = lazy(() =>
  import("./Components/Evaluation/Insights/Scores/ScorePage")
);

const LoginRouter = lazy(() => import("./Components/Login/LoginRouter"));
const Workbench = lazy(() =>
  import("./Components/EditorWithOutput/EditorWithOutput")
);
const Home = lazy(() => import("./Components/Home/HomeNew"));
const HomeStudent = lazy(() => import("./Components/HomePage/Index"));
const SubjectGoals = lazy(() =>
  import("./Components/HomePage/LearningPath/SubjectLearningPath")
);
const TopicLevels = lazy(() =>
  import("./Components/HomePage/userLearningGoals/index")
);
const Collection = lazy(() => import("./Components/Courses/Collection"));
const FilesRouter = lazy(() => import("./Components/Files/FilesRouter"));
const LearningContent = lazy(() =>
  import("./Components/LearningContent/LearningContent")
);
const PreviewAlchemyFile = lazy(() =>
  import("./Components/LearningContent/PreviewAlchemyFile")
);
const Community = lazy(() => import("./Components/Community"));
const Error = React.lazy(() => import("./Components/Error"));

const Courses = React.lazy(() => import("./Components/Courses"));
const Exercises = React.lazy(() => import("./Components/Courses/exercises"));
const ExerciseWorkbench = React.lazy(() =>
  import("./Components/Courses/workbench")
);
const Whiteboard = React.lazy(() => import("./Components/Whiteboard"));

const Evaluation = React.lazy(() => import("./Components/Evaluation"));

const ProtectedRoute = () => {
  const role = getRole();
  const theme = getTheme();
  const location = useLocation();
  const dispatch = useDispatch();

  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isDarkMode = useSelector(selectDarkMode);
  const isSidebarVisible = useSelector(showSidebarStatus);

  const handleToggle = () => {
    dispatch(toggleDarkMode());
  };

  const routes = [
    {
      path: "/home",
      component:
        role == "student" || role == "guest"
          ? HomeStudent
          : role == "mentor"
          ? MentorDashboard
          : Home,
      exact: true,
      allow: true,
    },
    {
      path: "/workbench",
      component: Workbench,
      exact: true,
      allow: true,
    },
    {
      path: "/workbench/personalCollection/:fileId",
      component: Workbench,
      exact: true,
      allow: true,
    },
    {
      path: "/workbench/classNote/:fileId",
      component: Workbench,
      exact: true,
      allow: true,
    },
    {
      path: "/workbench/assignment/:fileId",
      component: Workbench,
      exact: true,
      allow: true,
    },
    {
      path: "/workbench/exercise/:fileId",
      component: Workbench,
      exact: true,
      allow: true,
    },
    {
      path: "/files",
      component: FilesRouter,
      exact: false,
      allow: true,
    },
    {
      path: "/learningContent",
      component: LearningContent,
      exact: true,
      allow: role === "guest" ? false : true,
    },
    {
      path: "/exercises",
      component: Courses,
      exact: true,
      allow: true,
    },
    {
      path: "/exercises/assignedToMe",
      component: Courses,
      exact: true,
      allow: true,
    },
    {
      path: "/exercises/:catalogId",
      component: Exercises,
      exact: true,
      allow: true,
    },
    {
      path: "/exercises/assignedToMe/:catalogId",
      component: Exercises,
      exact: true,
      allow: true,
    },
    {
      path: "/exercises/goals/:topicId",
      component: Exercises,
      exact: true,
      allow: true,
    },
    {
      path: "/exercises/maths/:catalogId/:exerciseId",
      component: MathWorkSheet,
      exact: true,
      allow: true,
    },
    {
      path: "/exercises/sections/:catalogId/:exerciseId",
      component: Sections,
      exact: true,
      allow: true,
    },
    {
      path: `/exercises/collection/:collectionId`,
      component: Collection,
      exact: true,
      allow: true,
    },
    {
      path: `/exercises/collection/assignedToMe/:collectionId`,
      component: Collection,
      exact: true,
      allow: true,
    },
    {
      path: "/exercises/:catalogId/:exerciseId",
      component: ExerciseWorkbench,
      exact: true,
      allow: true,
    },
    {
      path: "/whiteboard/:type/:fileId",
      component: Whiteboard,
      exact: true,
      allow: true,
    },
    {
      path: "/whiteboard",
      component: Whiteboard,
      exact: true,
      allow: true,
    },
    {
      path: "/tracker",
      component: NewExerciseTracker,
      exact: true,
      allow: role === "mentor",
    },
    {
      path: "/tracker/:id",
      component: ExerciseTrackerCatalog,
      exact: true,
      allow: role === "mentor",
    },
    {
      path: "/goal/levels/:subject",
      component: SubjectGoals,
      exact: true,
      allow: true,
    },
    {
      path: "/goal/levels/:subject/:grade",
      component: TopicLevels,
      exact: true,
      allow: true,
    },
    {
      path: "/evaluation/scores/:assessmentId",
      component: EvaluationResults,
      exact: true,
      allow: role === "guest",
    },
    {
      path: "/evaluation/insights/:assessmentId",
      component: DetailedInsight,
      exact: true,
      allow: role === "guest",
    },
    {
      path: "/evaluation",
      component: Evaluation,
      exact: false,
      allow: role === "guest",
    },
    {
      path: "/new-learning-path",
      component: AppPathCreationContainer,
      exact: true,
      allow: role === "guest",
    },
  ];

  useEffect(() => {
    if (process.env.REACT_APP_ENABLE_NEWRELIC === "1") {
      const script = document.createElement("script");
      script.src = `/scripts/newrelic_browser_agent.js`;
      script.defer = true;
      document.head.appendChild(script);
    }
  }, []);

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{
          backgroundColor: location.pathname.includes("/sections")
            ? "#FAF6F4"
            : theme.palette.background.background0,
        }}
      >
        {isDesktop && !window.ReactNativeWebView && isSidebarVisible && (
          <Grid item container xs={1}>
            <SidebarComponent handleToggle={handleToggle} />
          </Grid>
        )}
        <Grid
          item
          xs={isDesktop && isSidebarVisible ? 11 : 12}
          style={{
            backgroundColor: location.pathname.includes("/sections")
              ? "#FAF6F4"
              : role === "mentor" && location.pathname.includes("/home")
              ? theme.palette.background.mentorHomeBackground
              : theme.palette.background.background0,
          }}
        >
          <div
            style={{
              display: "block",
              width: "100%",
              // minHeight:
              //   location.pathname.includes("/workbench") && isDarkMode
              //     ? "105vh"
              //     : "100vh",
              minHeight: location.pathname.includes("evaluation")
                ? "95vh"
                : "100vh",
              padding: matchesSM
                ? "0px 10px"
                : location.pathname.includes("/home") && role == "mentor"
                ? "0px"
                : "0px 32px",
            }}
          >
            {!window.ReactNativeWebView ? (
              <HeaderBar handleToggle={handleToggle} />
            ) : // Excludes only "evaluation/:assessmentId"
            !/^\/(evaluation(\/[^/]+)?|new-learning-path)$/.test(
                location.pathname
              ) ? (
              <AppHeaderBar />
            ) : (
              <></>
            )}
            <Suspense
              fallback={
                <div style={{ width: "100%", height: "100%" }}>
                  <LoaderComponent />
                </div>
              }
            >
              <Routes routes={routes} redirectTo="/home" />
            </Suspense>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

function App() {
  const darkModeTheme = useSelector(selectDarkMode);
  const [darkMode, setDarkMode] = React.useState(false);

  useEffect(() => {
    setDarkMode(darkModeTheme);
    const root = document.documentElement;
    root.style.setProperty(
      "--scrollcolour",
      theme.palette.background.background3
    );
    root.style.setProperty(
      "--scrolltrack",
      theme.palette.background.profileBackground
    );
  }, [darkModeTheme]);

  const theme = getTheme();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Fragment>
        <Router>
          <Suspense
            fallback={
              <div style={{ height: "100vh" }}>
                <LoaderComponent />
              </div>
            }
          >
            <Switch>
              <Route path="/" component={Login} exact />
              <Route path="/login" component={LoginRouter} />
              <Route path="/previewFile/:id" component={PreviewAlchemyFile} />
              <Route path="/404" component={Error} exact />
              <Route
                path={[
                  "/student/:id/1",
                  "/student/:id/",
                  "/user/:id/1",
                  "/user/:id",
                ]}
                component={Community}
                exact
                allow
              />
              <Route
                path="/evaluation/report/:assessmentId"
                component={DetailedInsight}
                exact
                allow
              />
              {window.ReactNativeWebView && (
                <Route path={"/native"} component={NativeComponent} exact />
              )}
              <Authentication>
                <ProtectedRoute />
              </Authentication>
              <Redirect to="/" />
            </Switch>
          </Suspense>
        </Router>
      </Fragment>
    </ThemeProvider>
  );
}

export default Sentry.withProfiler(App);
