import { useEffect } from "react";
import { encrypt } from "../../Core/utils/others";
import AES from "crypto-js/aes";
import setAuthCookies from "../../SharedComponents/setAuthCookies";

function NativeComponent() {
  useEffect(() => {
    const handleMessage = async (event) => {
      const data = event.data;

      // Handle the received data in your web app
      if (data?.authToken && data?.refreshToken) {
        // Remove old items
        localStorage.removeItem("sandbox_user");
        localStorage.removeItem("linkedUser");
        localStorage.removeItem("sud");
        localStorage.removeItem("appReturnUrl");

        const sandboxUser = {
          userId: data?.userId,
          userName: data?.userName,
          profilePicUrl: data?.profileImgUrl,
          email: data?.email,
          role: "guest",
          jupyterId: "guest",
          ACCESS_TOKEN: data?.authToken,
          REFRESH_TOKEN: data?.refreshToken,
        };
        const encryptedUserData = encrypt(sandboxUser);

        const userDetails = {
          role: "guest",
          email: data?.email,
        };

        await localStorage.setItem("sandbox_user", encryptedUserData);
        window.dispatchEvent(new Event("sandbox_user_update"));

        const sanboxUserData = {
          studentName: data?.userName,
          studentId: data?.studentId,
          grade: data?.grade,
        };

        const encryptedSandboxUserData = encrypt(sanboxUserData);
        await localStorage.setItem(
          "sandbox_user_data",
          encryptedSandboxUserData
        );

        localStorage.setItem(
          "linkedUser",
          encrypt({
            userType: 2,
            userId: data?.studentId,
            grade: data?.grade,
          })
        );

        const encryptedUD = AES.encrypt(
          JSON.stringify(userDetails),
          "secret key 123"
        ).toString();
        localStorage.setItem("sud", encryptedUD);
        setAuthCookies(sandboxUser, 1);

        if (data?.returnUrl) {
          localStorage.setItem("appReturnUrl", data.returnUrl);
        }

        console.log(data.url, "urllll");

        let path = data?.url.split("#")[1] || "";

        localStorage.setItem("landingUrl", path);
        window.location.href = data.url;
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  });
  return null;
}

export default NativeComponent;
