import { configureStore } from "@reduxjs/toolkit";

// We'll use redux-logger just as an example of adding another middleware
import logger from "redux-logger";
import { folderPathReducer } from "./Reducers/folderPath.reducer";
import { darkMode } from "./Reducers/darkMode.reducer";
import coursesSlice from "../Components/Courses/coursesSlice";
import darkModeSlice from "./Reducers/darkModeSlice";
import sidebarSlice from "./Reducers/sidebarSlice";

export const store = configureStore({
  reducer: {
    folderPathReducer: folderPathReducer,
    darkMode: darkMode,
    courses: coursesSlice,
    darkModeTheme: darkModeSlice,
    sidebar: sidebarSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  devTools: process.env.NODE_ENV !== "production",
  enhancers: [],
});

// The store has been created with these options:
// - The slice reducers were automatically passed to combineReducers()
// - redux-thunk and redux-logger were added as middleware
// - The Redux DevTools Extension is disabled for production
// - The middleware, batch, and devtools enhancers were composed together
