import React, { useEffect, useState } from "react";
import { Dialog, Box, Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import pop_1 from "../../../assets/images/pop_1.svg";
import pop_2 from "../../../assets/images/pop_2.svg";
import cancel_icon from "../../../assets/images/cancel_icon.png";
import { getDefaultImg } from "../helper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { API } from "../../../Services/api-service";
import { getRole, getUserId } from "../../../Core/utils/auth";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import { getIPInfo } from "../../../lib/IpInfo";
import { decrypt } from "../../../Core/utils/others";
import { useSelector } from "react-redux";
import { selectDarkMode } from "../../../Redux/Reducers/darkModeSlice";
import { navigateToQuiz } from "../../Home/NoahBanner/helper/navigation";

toast.configure();

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    width: "100%",
    fontFamily: "Inter",
    maxWidth: 600,
    background: theme.palette.background.profileBackground,
    padding: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
  },
  imageContainer: {
    height: 200,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: (props) => props.bgColor,
    marginBottom: theme.spacing(2),
  },
  mainHeading: {
    color: theme.palette.heading,
    fontWeight: 700,
    fontSize: "16px",
    margin: theme.spacing(2, 0, 1),
    textAlign: "left",
  },
  subHeading: {
    color: theme.palette.body,
    fontWeight: 500,
    fontSize: "14px",
    textAlign: "left",
    marginBottom: theme.spacing(2),
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "right",
    marginTop: theme.spacing(3),
  },
  stepHeading: {
    color: theme.palette.heading,
    fontWeight: 700,
    fontSize: 16,
    marginBottom: theme.spacing(1),
  },
  stepSubHeading: {
    color: theme.palette.body,
    fontWeight: 500,
    fontSize: 14,
    marginBottom: theme.spacing(3),
  },
  optionContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1),
    justifyContent: "center",
  },
  optionContainerForLanguage: {
    display: "flex",
    gap: theme.spacing(1.5),
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
      gap: theme.spacing(1),
    },
  },
  topicContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttonStepper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "25px",
  },
  subjectButton: {
    position: "relative",
    height: 80,
    width: 80,
    borderRadius: 8,
    padding: 10,
    border: theme.palette.bordereditorborder,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.learningPopUpSubject,
    cursor: "pointer",
    "&.selected": {
      border: "1px solid #E98451",
      backgroundColor: theme.palette.background.learningPopUpSubject,
    },
  },
  subjectBtnText: {
    color: theme.palette.body,
    fontSize: "12px",
    fontWeight: 500,
  },
  tickIcon: {
    position: "absolute",
    top: 5,
    right: 5,
    color: "#E98451",
    fontSize: 20,
  },
  topicButton: {
    display: "flex",
    alignItems: "center",
    width: "max-content",
    justifyContent: "space-between",
    border: "1px solid #E0E0E0",
    borderRadius: 8,
    padding: "6px 10px",
    marginBottom: theme.spacing(1),
    "&.selected": {
      border: "1px solid #E98451",
      color: "#E98451",
    },
    "&.unselected": {
      border: `1px solid ${theme.palette.body}`,
      color: theme.palette.body,
    },
  },
  topicButtonForLanguage: {
    display: "flex",
    alignItems: "center",
    width: "max-content",
    justifyContent: "space-between",
    backgroundColor: "#F2F7F7",
    borderRadius: 8,
    padding: "6px 10px",
    marginBottom: theme.spacing(1),
    color: "#569090",
  },

  expandButton: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    color: "#A3A3A3",
    marginTop: theme.spacing(2),
  },
  nextBtn: {
    color: "#FFF",
    background: "#E98451",
    minWidth: "100px",
    textTransform: "none",
    "&:hover": {
      color: "#FFF",
      background: "#E98451",
    },
  },
  btnBox: {
    display: "flex",
    justifyContent: "right",
  },
  previousBtn: {
    color: "#E98451",
    textTransform: "none",
    marginRight: "10px",
    "&:hover": {
      color: "#E98451",
    },
  },
  gradeButton: {
    height: 35,
    minWidth: 100,
    borderRadius: 8,
    padding: "0 10px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    background: theme.palette.background.learningPopUpSubject,
    alignItems: "center",
    border: theme.palette.bordereditorborder,
    color: theme.palette.body,
    cursor: "pointer",
    "&.selected": {
      border: "1px solid #E98451",
      backgroundColor: theme.palette.background.learningPopUpSubject,
      color: "#E98451",
    },
  },
  gradeButtonContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    justifyContent: "center",
  },
  gradeButtonContainerForLanguage: {
    display: "flex",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },

  unselectedTopicHeading: {
    fontWeight: 500,
    fontSize: "14px",
    color: theme.palette.heading,
  },
}));

const LearningCreationPopup = ({
  open,
  closeModal,
  isNewUser,
  setLearningData,
  setUserSubject,
  setHeaderStats,
  location,
}) => {
  const classes = useStyles();
  const { subject } = useParams();
  const isDarkMode = useSelector(selectDarkMode);
  const { grade } = useParams();
  const role = getRole();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(
    isNewUser
      ? -2
      : ["dashboard", "app"].includes(location)
      ? 0
      : location == "goals"
      ? 2
      : 1
  );
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedGrades, setSelectedGrades] = useState(null);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [unselectedTopics, setUnselectedTopics] = useState([]);
  const [subjectLevel, setSubjectLevel] = useState([]);
  const [showAllSelected, setShowAllSelected] = useState(false);
  const [showAllUnselected, setShowAllUnselected] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [ipDetails, setIpDetails] = useState(null);
  const sandboxUser = decrypt(localStorage.getItem("sandbox_user"));

  const initialSubjectList = [
    {
      subjectName: "Math",
      value: "math",
    },
    {
      subjectName: "English",
      value: "english",
    },
    {
      subjectName: "Science",
      value: "science",
    },

    {
      subjectName: "Scratch",
      value: "scratch",
    },
    {
      subjectName: "Web dev",
      value: "webdev",
    },
    {
      subjectName: "Python",
      value: "python",
    },

    {
      subjectName: "Java",
      value: "java",
    },
  ];

  const languageOption = {
    subjectName: "Languages",
    value: "language",
  };

  // This is done for time being to hide adding languages learning path through app.
  const subjectList =
    location !== "app"
      ? [
          ...initialSubjectList.slice(0, 3),
          languageOption,
          ...initialSubjectList.slice(3),
        ]
      : initialSubjectList;

  useEffect(() => {
    if (location == "subject" || location == "goals") {
      if (subject.toLowerCase().includes("web")) {
        setSelectedSubject("webDev");
      } else {
        setSelectedSubject(subject);
      }
    }
  }, []);
  useEffect(() => {
    if (location == "goals") {
      setSelectedGrades(grade);
    }
  }, []);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const details = await getIPInfo();
        setIpDetails(details);
      } catch (error) {
        console.error("Failed to get IP info", error);
      }
    };

    fetchDetails();
  }, []);

  const toggleExpandSelected = () => {
    setShowAllSelected((prev) => !prev);
  };

  const toggleExpandUnselected = () => {
    setShowAllUnselected((prev) => !prev);
  };

  const getSubjectLevels = () => {
    API.get(`/goals/${selectedSubject}/levels`)
      .then((res) => {
        if (selectedSubject == "language") {
          setSubjectLevel(["french", "spanish", "german", "hindi", "telugu"]);
        } else {
          setSubjectLevel(res.data?.grades);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getSelectedTopics = () => {
    API.get(`/goals/${selectedSubject}/${selectedGrades}?addLearningPath=1`)
      .then((res) => {
        if (res.data.learningPathExists) {
          const responseList = res.data?.list;
          const selected = responseList.filter((topic) => topic.exists === 1);
          const unselected = responseList.filter((topic) => topic.exists !== 1);
          setSelectedTopics(selected);
          setUnselectedTopics(unselected);
        } else {
          setSelectedTopics(res.data?.list);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (selectedGrades && selectedSubject) {
      getSelectedTopics();
    }
  }, [selectedGrades, selectedSubject, grade]);
  useEffect(() => {
    if (selectedSubject) {
      getSubjectLevels();
    }
  }, [selectedSubject]);

  const handleRemoveTopic = (topic) => {
    setSelectedTopics((prev) =>
      prev.filter((t) => t.topicId !== topic.topicId)
    );
    setUnselectedTopics((prev) => [...prev, topic]);
  };

  const handleAddTopic = (topic) => {
    setUnselectedTopics((prev) =>
      prev.filter((t) => t.topicId !== topic.topicId)
    );
    setSelectedTopics((prev) => [...prev, topic]);
  };

  const handleNext = () => {
    if (activeStep == 0 && !selectedSubject)
      return toast.error("Please Select the subject to proceed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    if (activeStep == 1 && !selectedGrades)
      return toast.error("Please Select the grade to proceed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    setActiveStep((prev) => prev + 1);
  };
  const handleBack = () => {
    setActiveStep((prev) => {
      const newStep = prev - 1;
      if (newStep === 1) {
        setSelectedTopics([]);
        setUnselectedTopics([]);
        setSelectedGrades(null);
      } else if (newStep === 0) {
        setSelectedGrades("");
        setSelectedSubject(null);
      }

      return newStep;
    });
  };

  const selectSubject = (subject) => {
    setSelectedSubject(subject.value);
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const submitGoals = () => {
    if (activeStep == 2 && selectedTopics.length == 0)
      return toast.error("Please Select the topics to proceed", {
        position: toast.POSITION.TOP_RIGHT,
      });
    setSubmitLoader(true);
    API.post(`/user/goals`, {
      topicIds: selectedTopics,
      grade: selectedGrades,
      subject: selectedSubject,
      tag: location,
      tz: ipDetails?.timezone?.id,
    })
      .then((res) => {
        console.log(
          res.data,
          "calleeeddd",
          selectedSubject,
          selectedGrades,
          location
        );
        if (res?.data?.success) {
          if (selectedSubject == "language") {
            const data = { subject: selectedGrades, type: "language" };
            navigateToQuiz(data);
          } else if (location == "subject") {
            setUserSubject(res?.data?.stats?.subjects);
            history.push(`/goal/levels/${selectedSubject}/${selectedGrades}`);
            // window.open(
            //   `${process.env.REACT_APP_SANDBOX_PORTAL_URL}#/goal/levels/${selectedSubject}/${selectedGrades}`,
            //   "_self"
            // );
          } else if (location == "dashboard") {
            setHeaderStats(res?.data?.stats?.completions);
            setLearningData(res?.data?.stats?.breakdown?.subjects);
            history.push(`/goal/levels/${selectedSubject}/${selectedGrades}`);
          } else if (location === "app") {
            history.push(`/goal/levels/${selectedSubject}/${selectedGrades}`);
          }
          toast.success(res.data.msg, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setSubmitLoader(false);
          if (location !== "app") {
            closeModal();
          }

          if (role == "guest") {
            localStorage.setItem("learningPopupShown", "true");
          }
        } else {
          toast.error(res?.data?.msg, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setSubmitLoader(false);
        }
      })
      .catch((err) => {
        console.log(err, "----rr");
        setSubmitLoader(false);
        toast.error(
          err?.response?.data?.msg || "Something went wrong Please try again",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      });
  };

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      maxWidth={
        activeStep == 2
          ? "sm"
          : selectedSubject == "language" && activeStep == 1
          ? "480px"
          : "xs"
      }
      sx={{
        position: "relative",
        background: isDarkMode ? "#1E1E1E" : "#FFFFFF",
      }}
    >
      <Box
        className={classes.btnBox}
        style={{ padding: "5px", position: "absolute", right: 1 }}
      >
        <CloseIcon
          fontSize="small"
          onClick={() => {
            closeModal();
            if (role == "guest") {
              localStorage.setItem("learningPopupShown", "true");
            }
          }}
          style={{ cursor: "pointer", color: !isDarkMode && "#2F4F4F" }}
        />
      </Box>
      <Box className={classes.dialogContainer}>
        {activeStep === -2 && (
          <>
            <Box
              className={classes.imageContainer}
              style={{ backgroundColor: "#FFEED4" }}
            >
              <img src={pop_1} alt="icon" style={{ objectFit: "contain" }} />
            </Box>
            <Grid className={classes.mainHeading}>
              {`Hello ${sandboxUser.userName}, Welcome to Sandbox`}
            </Grid>
            <Grid className={classes.subHeading}>
              Sandbox makes learning Coding and school subjects super fun! Solve
              cool exercises, set goals, build streaks, track your progress, and
              get help anytime from Noah, your AI buddy!
            </Grid>
            <Grid className={classes.btnBox}>
              <Button
                variant="contained"
                onClick={handleNext}
                style={{
                  background: !isDarkMode && "#E98451",
                }}
                className={classes.nextBtn}
              >
                Next
              </Button>
            </Grid>
          </>
        )}

        {activeStep === -1 && (
          <>
            <Box
              className={classes.imageContainer}
              style={{ backgroundColor: "#99E3FF" }}
            >
              <img src={pop_2} alt="icon" style={{ objectFit: "contain" }} />
            </Box>
            <Grid className={classes.mainHeading}>
              Personalize Sandbox for you in 3 steps
            </Grid>
            <Grid className={classes.subHeading}>
              Just pick your subject and grade, and Sandbox will create a
              personalized learning path just for you. With Noah, your AI
              mentor, you’ll get help with doubts and even quizzes to keep you
              on track!
            </Grid>
            <Box className={classes.buttonGroup}>
              <Button
                variant="outlined"
                className={classes.previousBtn}
                onClick={handleBack}
              >
                Previous
              </Button>
              <Button
                variant="contained"
                className={classes.nextBtn}
                style={{
                  background: !isDarkMode && "#E98451",
                }}
                onClick={handleNext}
              >
                Next
              </Button>
            </Box>
          </>
        )}

        {/* Main Steps */}
        {activeStep >= 0 && (
          <div>
            <Grid className={classes.stepHeading}>Make Sandbox yours!</Grid>
            <Grid className={classes.stepSubHeading}>
              {activeStep === 0
                ? "Choose the subject you want to explore"
                : activeStep === 1
                ? selectedSubject === "language"
                  ? "Choose the language you want to learn"
                  : "Select your grade to get content tailored for you!"
                : selectedSubject == "language"
                ? "Step by step, you'll master these topics in language learning!"
                : "Here are the topics recommended for you! Feel free to make changes if required"}
            </Grid>

            {/* Step 1: Subjects */}
            {activeStep === 0 && (
              <>
                <Box className={classes.optionContainer}>
                  {subjectList?.map((subject) => (
                    <Box
                      key={subject.value}
                      className={`${classes.subjectButton} ${
                        selectedSubject === subject.value ? "selected" : ""
                      }`}
                      onClick={() => selectSubject(subject)}
                    >
                      <img
                        src={getDefaultImg(subject.subjectName.toLowerCase())}
                        alt="icon"
                        style={{
                          height: "48px",
                          width: "48px",
                          objectFit: "contain",
                        }}
                      />
                      <Grid className={classes.subjectBtnText}>
                        {capitalizeFirstLetter(subject.subjectName)}
                      </Grid>
                      {selectedSubject === subject.value && (
                        <CheckCircleIcon className={classes.tickIcon} />
                      )}
                    </Box>
                  ))}
                </Box>
              </>
            )}

            {/* Step 2: Grades */}
            {activeStep === 1 && (
              <Box
                className={`${
                  selectedSubject === "language"
                    ? classes.gradeButtonContainerForLanguage
                    : classes.gradeButtonContainer
                }`}
              >
                {selectedSubject === "language" ? (
                  <Box className={classes.optionContainerForLanguage}>
                    {subjectLevel?.map((subject) => (
                      <Box
                        key={subject}
                        className={`${classes.subjectButton} ${
                          selectedGrades === subject ? "selected" : ""
                        }`}
                        onClick={() => setSelectedGrades(subject)}
                      >
                        <img
                          src={getDefaultImg(subject.toLowerCase())}
                          alt="icon"
                          style={{
                            height: "48px",
                            width: "48px",
                            objectFit: "contain",
                          }}
                        />
                        <Grid className={classes.subjectBtnText}>
                          {capitalizeFirstLetter(subject)}
                        </Grid>
                        {selectedSubject === subject && (
                          <CheckCircleIcon className={classes.tickIcon} />
                        )}
                      </Box>
                    ))}
                  </Box>
                ) : (
                  subjectLevel?.map((grade) => (
                    <Box
                      key={grade}
                      className={`${classes.gradeButton} ${
                        selectedGrades === grade ? "selected" : ""
                      }`}
                      onClick={() => setSelectedGrades(grade)}
                    >
                      {capitalizeFirstLetter(grade)}
                    </Box>
                  ))
                )}
              </Box>
            )}

            {/* Step 3: Topics */}
            {activeStep === 2 && (
              <>
                <Box>
                  <Box className={classes.topicContainer}>
                    {selectedTopics
                      .slice(0, showAllSelected ? selectedTopics.length : 5)
                      .map((topic) => (
                        <Box
                          key={topic.topicId}
                          className={`${
                            selectedSubject == "language"
                              ? classes.topicButtonForLanguage
                              : classes.topicButton
                          } selected`}
                          onClick={
                            selectedSubject === "language"
                              ? undefined
                              : () => handleRemoveTopic(topic)
                          }
                        >
                          {capitalizeFirstLetter(topic.topicName)}
                          {selectedSubject != "language" && (
                            <img
                              src={cancel_icon}
                              alt="icon"
                              style={{ cursor: "pointer", marginLeft: "8px" }}
                              className={classes.topicButtonIcon}
                            />
                          )}
                        </Box>
                      ))}
                  </Box>
                  {selectedTopics.length - 5 > 0 && (
                    <Grid
                      className={classes.expandButton}
                      onClick={toggleExpandSelected}
                    >
                      {showAllSelected ? (
                        <span>
                          Collapse <ExpandLessIcon />
                        </span>
                      ) : (
                        <span>
                          Expand All <ExpandMoreIcon /> (
                          {selectedTopics.length - 5} more)
                        </span>
                      )}
                    </Grid>
                  )}
                </Box>

                <Box>
                  {unselectedTopics.length > 0 ? (
                    <Grid className={classes.mainHeading}>Add Topics:</Grid>
                  ) : (
                    ""
                  )}
                  <Box className={classes.topicContainer}>
                    {unselectedTopics
                      .slice(0, showAllUnselected ? unselectedTopics.length : 5)
                      .map((topic) => (
                        <Box
                          key={topic.topicId}
                          className={`${classes.topicButton} unselected`}
                          onClick={() => handleAddTopic(topic)}
                        >
                          {capitalizeFirstLetter(topic.topicName)}
                          <AddIcon className={classes.topicButtonIcon} />
                        </Box>
                      ))}
                  </Box>
                  {unselectedTopics.length - 5 > 0 && (
                    <Grid
                      className={classes.expandButton}
                      onClick={toggleExpandUnselected}
                    >
                      {showAllUnselected ? (
                        <span>
                          Collapse <ExpandLessIcon />
                        </span>
                      ) : (
                        <span>
                          Expand All <ExpandMoreIcon /> (
                          {unselectedTopics.length - 5} more)
                        </span>
                      )}
                    </Grid>
                  )}
                </Box>
              </>
            )}

            <Box className={classes.buttonStepper}>
              <Grid className={classes.subjectBtnText}>
                Step {activeStep + 1} of 3
              </Grid>
              <Box className={classes.btnBox}>
                {activeStep > 0 && (
                  <Button
                    variant="outlined"
                    className={classes.previousBtn}
                    onClick={handleBack}
                  >
                    Previous
                  </Button>
                )}
                {activeStep < 2 ? (
                  <Button
                    variant="contained"
                    className={classes.nextBtn}
                    style={{
                      background: !isDarkMode && "#E98451",
                    }}
                    disabled={
                      (activeStep == 0 && !selectedSubject) ||
                      (activeStep == 1 && !selectedGrades)
                    }
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    className={classes.nextBtn}
                    style={{
                      background: !isDarkMode && "#E98451",
                    }}
                    onClick={submitGoals}
                    disabled={
                      submitLoader ||
                      (activeStep == 2 && selectedTopics.length == 0)
                    }
                  >
                    {submitLoader ? (
                      <Spinner animation="border" variant="white" />
                    ) : (
                      "Start Learning"
                    )}
                  </Button>
                )}
              </Box>
            </Box>
          </div>
        )}
      </Box>
    </Dialog>
  );
};

export default LearningCreationPopup;
