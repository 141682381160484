import React, { useState, useEffect, useRef } from "react";
import { AppBar, IconButton, makeStyles, Toolbar } from "@material-ui/core";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import { useHistory, useLocation } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: "20px -10px 0 -10px",
  },
  appBar: {
    color: "#FF712D",
    backgroundColor: "#fff",
    height: "56px",
  },
  menuWrapper: {
    display: "flex",
    justifyContent: "space-between",
    flexGrow: 1,
    alignItems: "center",
  },
}));

const AppHeaderBar = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleWebview = () => {
    if (history.location.pathname === localStorage.getItem("landingUrl")) {
      const mobileData = {
        type: "route",
      };
      window.ReactNativeWebView.postMessage(JSON.stringify(mobileData));
    } else {
      history.goBack();
    }
  };

  const handleHome = () => {
    const mobileData = {
      type: "route",
    };
    window.ReactNativeWebView.postMessage(JSON.stringify(mobileData));
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        className={classes.appBar}
        style={{
          boxShadow: "none",
          borderBottom: "1px solid #FDE5D9",
        }}
      >
        <Toolbar>
          <div className={classes.menuWrapper}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="#2F4F4F"
              aria-label="open drawer"
              onClick={handleWebview}
            >
              <ArrowBackIcon />
            </IconButton>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="#2F4F4F"
              aria-label="open drawer"
              onClick={handleHome}
            >
              <HomeOutlinedIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default AppHeaderBar;
