import Axios from "axios";
import sessionExpired from "../Core/sessionExpired";
import { decrypt, encrypt } from "../Core/utils/others";
import setAuthCookies from "../SharedComponents/setAuthCookies";
// import { BASE_URL } from "./../Core/constants";

let accesToken = decrypt(localStorage.getItem("sandbox_user"));
const BASE_URL = process.env.REACT_APP_SANDBOX_HTTP_BASE_URL;
const api = function (baseUrl) {
  this.apiBase = baseUrl;
  this.accesToken = accesToken ? accesToken.ACCESS_TOKEN : "";
  this.refreshToken = accesToken ? accesToken.REFRESH_TOKEN : "";
  this.axios = Axios.create({
    baseURL: this.apiBase,
    timeout: 100000000,
  });

  let isRefreshing = false;
  let refreshApis = [];

  const storeExpiredApis = (cb) => {
    refreshApis.push(cb);
  };

  const onRefreshed = (token) => {
    refreshApis.map((cb) => cb(token));
  };

  // Listen for "sandbox_user_update" event
  window.addEventListener("sandbox_user_update", () => {
    const updatedToken = decrypt(localStorage.getItem("sandbox_user"));
    this.accesToken = updatedToken ? updatedToken.ACCESS_TOKEN : "";
    this.refreshToken = updatedToken ? updatedToken.REFRESH_TOKEN : "";

    // Ensure axios instance uses updated token
    this.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.accesToken}`;
  });

  this.axios.defaults.headers.post["Content-Type"] = "application/json";
  this.axios.interceptors.request.use(
    async (req) => {
      req.headers["Authorization"] = `Bearer ${this.accesToken}`;
      req.headers["refresh-token"] = this.refreshToken ? this.refreshToken : "";
      if (window.ReactNativeWebView) {
        req["headers"]["auth-source"] = "cy-sso";
      }
      return req;
    },
    (e) => Promise.reject(e)
  );

  this.axios.interceptors.response.use(
    (res) => res,
    async (error) => {
      const originalRequest = error.config;
      if (error.response?.status === 401 && !originalRequest._retry) {
        if (
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          ) &&
          window.ReactNativeWebView
        ) {
          if (isRefreshing) {
            return new Promise((resolve) => {
              storeExpiredApis((token) => {
                originalRequest.headers["Authorization"] = "Bearer " + token;
                resolve(this.axios(originalRequest));
              });
            });
          }

          originalRequest._retry = true;
          isRefreshing = true;

          try {
            const tokenObj = decrypt(localStorage.getItem("sandbox_user"));
            const response = await Axios.post(
              `${process.env.REACT_APP_SSO_BASE_URL}api/login/validate/refresh`,
              {
                accessToken: tokenObj.ACCESS_TOKEN,
                refreshToken: tokenObj.REFRESH_TOKEN,
              }
            );
            const { accessToken, refreshToken } = response.data;
            const authObj = {
              ACCESS_TOKEN: accessToken,
              REFRESH_TOKEN: refreshToken,
            };
            const encryptedUserData = encrypt(authObj);
            await localStorage.setItem("user", encryptedUserData);
            originalRequest["headers"][
              "Authorization"
            ] = `Bearer ${accessToken}`;
            onRefreshed(accessToken);
            originalRequest["headers"]["refresh-token"] = refreshToken;
            return this.axios(originalRequest);
          } catch (refreshError) {
            // if (window.ReactNativeWebView) {
            //   const mobileData = {
            //     type: "route",
            //   };
            //   window.ReactNativeWebView.postMessage(JSON.stringify(mobileData));
            // }
            return Promise.reject(refreshError);
          } finally {
            isRefreshing = false;
            refreshApis = [];
          }
        } else {
          const originalRequest = error.config;
          originalRequest._retry = true;
          const refresh = decrypt(localStorage.getItem("sandbox_user"));

          let access_token;
          try {
            access_token = await refreshAccessToken(refresh.REFRESH_TOKEN);
          } catch (e) {
            sessionExpired();
            return Promise.reject();
          }

          const LsItem = decrypt(localStorage.getItem("sandbox_user"));
          LsItem["ACCESS_TOKEN"] = access_token.data.ACCESS_TOKEN;
          LsItem["REFRESH_TOKEN"] = access_token.data.REFRESH_TOKEN;
          localStorage.setItem("sandbox_user", encrypt(LsItem));

          // * Set Global access token
          this.axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${access_token.data.ACCESS_TOKEN}`;

          // * Set access token for current Instance ( Original Request )
          originalRequest.headers.Authorization = `Bearer ${access_token.data.ACCESS_TOKEN}`;

          try {
            return await this.axios(originalRequest);
          } catch (e) {
            console.log("Error : ", e);
          }
        }
        if (error.response?.status === 403) {
          sessionExpired();
        }
      }
      return Promise.reject(error);
    }
  );

  const refreshAccessToken = async (refreshToken) => {
    const refreshToken1 = await this.axios.post("/getNewRefreshToken", {
      refreshToken,
    });
    setAuthCookies(refreshToken1.data);
    return refreshToken1;
  };
};

api.prototype.getApiBase = function () {
  return this.apiBase;
};

api.prototype.get = function (url, params = {}, data = {}) {
  return this.axios({
    method: "get",
    url,
    params,
    data,
  });
};

api.prototype.post = function (url, data = {}, params = {}, headers = {}) {
  const addHeaders = Object.keys(headers).length ? { ...headers } : {};
  return this.axios({
    method: "post",
    url,
    data,
    params,
    ...addHeaders,
  });
};

api.prototype.put = function (url, data, params = {}) {
  return this.axios({
    method: "put",
    url,
    data,
    params,
  });
};

api.prototype.delete = function (url, data) {
  return this.axios({
    method: "delete",
    url,
    data,
  });
};

api.prototype.updateTokens = function (data) {
  this.accesToken = data.ACCESS_TOKEN;
  this.refreshToken = data.REFRESH_TOKEN;
};

const API = new api(BASE_URL);
const API_CUSTOMERDEMO = new api(
  process.env.REACT_APP_CUSTOMERDEMO_HTTP_BASE_URL
);

export { API, API_CUSTOMERDEMO };
