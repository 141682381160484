import Cookies from "js-cookie";
import java from "../../assets/images/java_final_icon.png";
import scratch from "../../assets/images/scratch_final_icon.png";
import webdev from "../../assets/images/webdev_final_icon.png";
import web from "../../assets/images/webdev_final_icon.png";
import mathematics from "../../assets/images/math_final_icon.png";
import python from "../../assets/images/python_final_icon.png";
import mit from "../../assets/images/mit_final_logo.svg";
import languages from "../../assets/images/lang.png";
import app from "../../assets/images/app_final_logo.svg";
import english from "../../assets/images/english_final_icon.png";
import robotics from "../../assets/images/robotics_final_icon.png";
import science from "../../assets/images/science_final_icon.png";
import math from "../../assets/images/math_final_icon.png";
import game from "../../assets/images/python_final_icon.png";
import french from "../../assets/images/fre.png";
import hindi from "../../assets/images/hin.png";
import telugu from "../../assets/images/tel.png";
import spanish from "../../assets/images/spn.png";
import german from "../../assets/images/ger.png";
import { decrypt } from "../../Core/utils/others";
import { Tooltip, withStyles } from "@material-ui/core";
import jwt_decode from "jwt-decode";
import { getRole } from "../../Core/utils/auth";

export const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#FFFFFF",
    color: "#569090",
    border: "0.2px solid #CCCCCC",
    borderRadius: "8px",
    fontSize: 14,
    padding: "8px",
  },
}))(Tooltip);

export const getDefaultImg = (courseName, isDarkMode) => {
  const defaultImages = {
    scratch,
    java,
    mit,
    python,
    webdev,
    web,
    mathematics,
    app,
    english,
    robotics,
    science,
    math,
    game,
    languages,
    french,
    hindi,
    telugu,
    spanish,
    german,
  };

  const image = courseName?.split(" ")[0].toLowerCase();
  return defaultImages[image] ? defaultImages[image] : null;
};

export const navigateToQuiz = (type, subject) => {
  const sandbox_user_data = decrypt(localStorage.getItem("sandbox_user_data"));
  console.log(sandbox_user_data, "sandbox user");
  const accesToken = decrypt(localStorage.getItem("sandbox_user"));
  const userInfo = jwt_decode(accesToken.ACCESS_TOKEN).userInfo;
  const userId = sandbox_user_data?.studentId || userInfo.userId;
  const role = getRole();
  const userName = sandbox_user_data?.studentName || accesToken.userName;
  const sandboxUser = decrypt(localStorage.getItem("sandbox_user"));
  const userID =
    userInfo?.userId || jwt_decode(sandboxUser.ACCESS_TOKEN)?.userId;
  const allowedUsers = process.env?.REACT_APP_ALLOWED_USERS?.split(",") || [];

  Cookies.set("userName", sandbox_user_data?.studentName, {
    path: "/",
    domain: ".codeyoung.com",
  });

  Cookies.set("userId", userID, {
    path: "/",
    domain: ".codeyoung.com",
  });

  if (role == "guest") {
    Cookies.set("grade", Number(sandbox_user_data?.grade), {
      path: "/",
      domain: ".codeyoung.com",
    });
  } else {
    Cookies.set("grade", sandbox_user_data?.grade, {
      path: "/",
      domain: ".codeyoung.com",
    });
  }

  Cookies.set("userRole", role, {
    path: "/",
    domain: ".codeyoung.com",
  });
  const url = process.env.REACT_APP_AI_QUIZ_URL_DEV;

  if (type == "doubt-solving") {
    window.open(`${url}chat-bot`);
  } else if (
    type == "start-quiz" &&
    (subject.toLowerCase() == "math" ||
      subject.toLowerCase() == "science" ||
      subject.toLowerCase() == "english")
  ) {
    if (window.ReactNativeWebView) {
      window.location.href = `${url}subject-dashboard?subject=${subject.toLowerCase()}`;
    } else {
      window.open(`${url}subject-dashboard?subject=${subject.toLowerCase()}`);
    }
  } else {
    window.open(`${url}`);
  }
};
