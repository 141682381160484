import { Box, Button, Grid } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import { useStyles } from "./style";
import InstructionType from "./InstructionType";
import Questions from "./Questions";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import KeyboardBackspaceOutlinedIcon from "@material-ui/icons/KeyboardBackspaceOutlined";
import { API } from "../../../Services/api-service";
import Instructions from "./Instructions";
import Video from "./Video";
import SectionSuccessPage from "./SectionSuccessPage";
import { getRole } from "../../../Core/utils/auth";
import AddExercise from "./Admin/AddExercise";
import FlipToFrontOutlinedIcon from "@mui/icons-material/FlipToFrontOutlined";
import AdminQuestions from "./Admin/AdminQuestions";
import MoveSectionDialog from "./Admin/MoveSectionDialog";
import BackIcon from "@mui/icons-material/ChevronLeft";
import NextIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@material-ui/core/IconButton";
import startTimeFunction from "../startTime";
import ExerciseOverview from "../ExerciseOverview";
import Loader from "../../../Core/Loader/Loader";
import Timer from "../Timer";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import SaveIcon from "@material-ui/icons/SaveOutlined";
import Preview from "./Preview";

const Index = () => {
  const classes = useStyles();
  const history = useHistory();
  const initialDataFetchedRef = useRef(false);
  const dataFetchedRef = useRef(false);
  const role = getRole();
  const { catalogId, exerciseId } = useParams();
  const [exercise, setExercise] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [showTimer, setShowTimer] = useState(
    getRole() === "student" || getRole() === "guest"
  );
  const [timerDuration, setTimerDuration] = useState(null);
  const [startAssessmentTime, setStartAssessmentTime] = useState("");
  const [endAssessmentTime, setEndAssessmentTime] = useState("");
  const [showAssessmentTimer, setShowAssessmentTimer] = useState(false);
  const [endTime, setEndTime] = useState("");
  const [attemptId, setAttemptId] = useState(null);
  const [exerciseOverview, setExerciseOverview] = useState(false);
  const [questionSetIndex, setQuestionSetIndex] = useState(0);
  const [questionLevelData, setQuestionLevelData] = useState();
  const [questionData, setQuestionData] = useState(null);
  const [sections, setSections] = useState([]);
  const [isExerciseCompleted, setIsExerciseCompleted] = useState(false);
  const [sectionLevelData, setSectionLevelData] = useState();
  const [addQustionTypeModal, setAddQustionTypeModal] = useState(false);
  const [videoEditor, setVideoEditor] = useState(false);
  const [textEditor, setTextEditor] = useState(false);
  const [stickyType, setStickyType] = useState(false);
  const [singleQuestion, setSingleQuestion] = useState(false);
  const [viewResults, setViewResults] = useState(false);
  const [answer, setAnswer] = useState({});
  const [sectionNo, setSectionNo] = useState(0);
  const [nextSection, setNextSection] = useState(null);
  const [prevSection, setPrevSection] = useState(null);
  const [iconDisabled, setIconDisabled] = useState(null);
  const [currentSectionId, setCurrentSectionId] = useState("");
  const [parentQuestionId, setParentQuestionId] = useState(null);
  const [completedTime, setCompletedTime] = useState("");
  const [openMoveSectionDialog, setOpenMoveSectionDialog] = useState(false);
  const [nextLoader, setNextLoader] = useState(false);
  const [currentAdminSubQuestionNo, setCurrentAdminSubQuestionNo] = useState(0);
  const [preview, setPreview] = useState(false);
  const [previewMode, setPreviewMode] = useState("0");
  const [imagesArray, setImagesArray] = useState([]);

  const [hasAnswered, setHasAnswered] = useState(false);
  const [openEnded, setOpenEnded] = useState(false);
  const [isNewQuestion, setIsNewQuestion] = useState(false);

  const handleNewSubQuestion = () => {
    setImagesArray([]);
    setIsNewQuestion(true);
  };

  const startTimePastSevenDays = (submissionTime) => {
    if (submissionTime?.endTime) return false;
    const oneDay = 24 * 60 * 60 * 1000;
    const diffDays = Math.round(
      Math.abs((new Date().getTime() - submissionTime.startTime) / oneDay)
    );
    if (diffDays >= 7) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (initialDataFetchedRef.current) return;
    initialDataFetchedRef.current = true;

    const isPreview = localStorage.getItem("questionnairePreview");
    if (isPreview === "true") {
      setPreview(true);
      setPreviewMode("1");
    }
  }, []);

  useEffect(() => {
    if (!initialDataFetchedRef.current || dataFetchedRef.current) return;

    const isPreview = localStorage.getItem("questionnairePreview");
    if (isPreview === "true" && previewMode === "1") {
      fetchExercise();
      getSections();
      dataFetchedRef.current = true;
      localStorage.setItem("questionnairePreview", false);
    } else if (isPreview !== "true") {
      fetchExercise();
      getSections();
      dataFetchedRef.current = true;
    }
  }, [previewMode]);

  const fetchExercise = () => {
    setLoading(true);
    API.get(`/exercises`, {
      catalogId: catalogId,
      exerciseId: exerciseId,
    })
      .then((res) => {
        const recentSubmissionTime =
          res.data.responseData?.recentAttempt?.submissionTime;
        const normalSubmission =
          res.data.responseData?.submission?.submissionTime;
        setExercise(res.data.responseData);
        if (res.data.responseData.recentAttempt?.attemptId) {
          setAttemptId(res.data.responseData?.recentAttempt?.attemptId);
        }
        if (res.data?.responseData?.properties?.hasOwnProperty("timer")) {
          setTimerDuration(res?.data?.responseData.properties.timer);
        }
        setIsExerciseCompleted(res?.data?.responseData.isExerciseCompleted);
        const submissionTime = normalSubmission?.hasOwnProperty("startTime")
          ? normalSubmission
          : recentSubmissionTime;

        if (
          res.data.responseData.isExerciseCompleted === 1 &&
          !submissionTime
        ) {
          setShowTimer(false);
          setShowAssessmentTimer(false);
        } else if (
          submissionTime &&
          !res?.data?.responseData?.properties?.timer
        ) {
          if (startTimePastSevenDays(submissionTime)) {
            const now = new Date().getTime();
            setStartTime(now);
            startTimeFunction({
              startTime: now,
              exerciseId,
              mathWorksheet: 0,
              reAttempt: 1,
              catalogId,
              language: res.data.responseData?.language,
            });
          } else {
            setStartTime(submissionTime.startTime);
            if (
              showTimer &&
              (res.data.responseData.submission?.attemptedAll ||
                res.data?.responseData?.isExerciseCompleted === 1)
            ) {
              setEndTime(submissionTime.endTime);
            }
          }
        } else if (
          submissionTime &&
          res?.data?.responseData?.properties?.timer
        ) {
          setStartAssessmentTime(submissionTime.startTime);
          if (res.data.responseData.submission?.attemptedAll) {
            setEndAssessmentTime(submissionTime.endTime);
          }
        }
        setExerciseOverview(true);
        setLoading(false);
      })
      .catch((err) => {
        console.error("error while fetching exersie data", err);
      });
  };

  const clearAdminEditors = () => {
    if (role === "admin") {
      setStickyType(false);
      setTextEditor(false);
      setVideoEditor(false);
      setSingleQuestion(false);
      setCurrentAdminSubQuestionNo(0);
    }
  };

  const addSectionHandler = () => {
    API.post(`/${exerciseId}/questionnaire/section`)
      .then((res) => {
        clearAdminEditors();

        setAddQustionTypeModal(true);

        getSections(res?.data?.sectionId);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const closeModalHandler = () => {
    setAddQustionTypeModal(false);
  };

  // const questionData = questionLevelData?.sections[0]?.questions[0];

  const getSections = (sectionId = null, viewResultCalled = 0, preview = 0) => {
    let isPreview;
    if (preview === 1 || previewMode === "1") {
      isPreview = 1;
    } else {
      isPreview = 0;
    }

    API.get(
      `/questionnaire/${exerciseId}?viewResults=${viewResultCalled}&preview=${isPreview}`
    )
      .then((res) => {
        if (viewResultCalled) {
          if (res.data.data?.viewResults?.allowed === 0) {
            toast.error(res.data.data?.viewResults?.msg, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setViewResults(false);
            return;
          } else {
            setViewResults(true);
          }
        }
        setSections(res.data.data?.sections);
        setSectionLevelData(res.data.data?.total);
        if (res.data && res.data?.data?.completedIn) {
          setCompletedTime(res.data?.data?.completedIn);
        }
        if (res.data) {
          if (sectionId) {
            getSectionWiseData(sectionId, preview);
          } else {
            getSectionWiseData(res.data.data?.sections[0]?.sectionId, preview);
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getSectionWiseData = (id, preview = 0) => {
    setLoading(true);
    let isPreview;
    if (preview === 1 || previewMode === "1") {
      isPreview = 1;
    } else {
      isPreview = 0;
    }
    API.get(`/questionnaire/${exerciseId}/${id}?preview=${isPreview}`)
      .then((res) => {
        if (res?.data?.data) {
          let response = res.data.data;

          setQuestionLevelData(res?.data?.data);

          if (res.data.data) {
            setQuestionData(res?.data?.data?.sections[0]?.questions[0]);
          }
          setSectionLevelData(res?.data?.data?.total);
          if (role === "admin") {
            const questionType =
              response?.sections[0]?.questions[0]?.questionType;
            if (questionType === "Instruction - Text") {
              setTextEditor(true);
            } else if (questionType === "Instruction - Video") {
              setVideoEditor(true);
            } else if (
              questionType === "Open-ended" ||
              questionType === "Text"
            ) {
              setSingleQuestion(true);
            } else if (
              questionType === "Single Select" ||
              questionType === "Multi Select"
            ) {
              setSingleQuestion(true);
            } else if (questionType === "Question Set") {
              setStickyType(true);
              setParentQuestionId(
                response?.sections[0]?.questions[0]?.questionId
              );
            } else {
              setParentQuestionId(null);
            }
          }

          if (
            response?.sections &&
            Object.keys(response?.sections[0]).length > 0
          ) {
            let section = response?.sections[0];
            setSectionNo(section?.sectionNo);
            setNextSection(section?.nextSection);
            setPrevSection(section?.prevSection);
            setCurrentSectionId(section?.sectionId);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const handleClose = () => {
    setExerciseOverview(false);
  };

  const handleAnswers = (questionType, questionId, answers) => {
    if (questionType === "Single Select") {
      setAnswer((prevAnswers) => ({
        [questionId]: answers,
      }));
    } else if (questionType === "Multi Select") {
      setAnswer((prevAnswers) => ({
        [questionId]: answers,
      }));
    } else if (questionType === "Open-ended") {
      setAnswer((prevAnswers) => ({
        [questionId]: [{ answer: answers[0] }],
      }));
    } else if (questionType === "Text") {
      setAnswer((prevAnswers) => ({
        [questionId]: [{ answer: answers }],
      }));
    } else {
      setAnswer((prevAnswers) => ({
        [questionId]: answers,
      }));
    }
  };

  const handleReattempt = () => {
    API.post(`/exercises/${exerciseId}/reattempt`)
      .then((res) => {
        toast.success("Exercise attempt successfull", {
          position: toast.POSITION.TOP_RIGHT,
        });

        if (preview) {
          localStorage.setItem("questionnairePreview", true);
        }
        window.location.reload();
      })
      .catch((err) => {
        toast.error("Something went wrong please try again", {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.log("res", err);
      });
  };

  const handleViewResults = () => {
    getSections(null, 1);
  };

  const handlePrevious = () => {
    setImagesArray([]);
    if (prevSection) {
      setQuestionSetIndex(0);
      getSectionWiseData(prevSection);
    }
    clearAdminEditors();
  };

  const getSectionDetailsWithoutSaving = (action) => {
    if (
      questionData?.questionType === "Question Set" &&
      questionSetIndex < questionData?.subQuestions?.length - 1 &&
      action !== "nextSection"
    ) {
      setQuestionSetIndex(questionSetIndex + 1);
    } else if (nextSection) {
      setQuestionSetIndex(0);
      getSectionWiseData(nextSection);
    }
    clearAdminEditors();
    setAnswer({});
    setHasAnswered(false);
  };

  const getSectionDetailsAfterSaving = (action) => {
    if (!hasAnswered && action === "saveProgress") {
      return toast.error("Please make changes to save your answer", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (!hasAnswered) {
      return toast.error("Please answer before going forward", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (Object.keys(answer).length > 0) {
      setNextLoader(true);
      const formData = new FormData();
      formData.append("language", "english");
      formData.append("exerciseId", exerciseId);
      formData.append("catalogId", catalogId);
      formData.append("mathWorksheet", 0);
      formData.append("type", "Questionnaire");
      formData.append("category", "Questionnaire");
      formData.append("reAttempt", 0);
      formData.append("sectionId", currentSectionId);
      formData.append("questionnaireResp", JSON.stringify(answer));
      if (action === "saveProgress") {
        formData.append("saveOnly", 1);
      }
      if (previewMode === "1") {
        formData.append("previewModeQuestionnaire", 1);
      }
      // if (!timerDuration) {
      //   setShowTimer(getRole() == "student" || getRole() == "guest");
      //   const now = new Date().getTime();
      //   setStartTime(now);
      //   setEndTime("");
      //   formData.append("startTime", now);
      // }

      // if (timerDuration) {
      //   setRestartTimer(true);
      //   setTimeout(() => {
      //     setRestartTimer(false);
      //   }, 0);
      //   setShowAssessmentTimer(getRole() == "student" || getRole() == "guest");
      //   const now = new Date().getTime();
      //   setStartAssessmentTime(now);
      //   setEndAssessmentTime("");
      //   formData.append("startTime", now);
      // }
      if (action === "finish") {
        if (showTimer && !timerDuration) {
          const now = new Date().getTime();
          formData.append("startTime", startTime);
          formData.append("endTime", now);
        }
      } else {
        if (showTimer && !timerDuration) {
          formData.append("startTime", startTime);
        }
      }
      formData.append("attemptId", attemptId ? attemptId : "");
      // else if (showAssessmentTimer && timerDuration) {
      //   const now = new Date().getTime();
      //   formData.append("startTime", startAssessmentTime);
      //   formData.append("endTime", now);
      // }

      API.post("/exercises", formData)
        .then((res) => {
          setNextLoader(false);
          // toast.success(res.data.msg, {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
          // if (res.data.recentAttempt.attemptId) {
          //   setAttemptId(res.data.recentAttempt.attemptId);
          // }
          // if (
          //   res.data.submission?.attempted &&
          //   res.data.submission.attemptedAll &&
          //   showTimer &&
          //   !timerDuration
          // ) {
          //   setEndTime(formData.get("endTime"));
          // }
          // if (
          //   res.data.submission?.attempted &&
          //   res.data.submission.attemptedAll &&
          //   showAssessmentTimer &&
          //   timerDuration
          // ) {
          //   setEndAssessmentTime(formData.get("endTime"));
          // }
          setAnswer({});
          setHasAnswered(false);
          if (res.data.recentAttempt?.attemptId) {
            setAttemptId(res.data.recentAttempt?.attemptId);
          }
          if (preview && action === "finish") {
            setIsExerciseCompleted(true);
          } else {
            setIsExerciseCompleted(res.data?.isExerciseCompleted);
          }
          setCompletedTime(res.data?.completedIn);
          setSectionLevelData(res.data?.total);
          if (action !== "saveProgress") {
            if (
              questionData?.questionType === "Question Set" &&
              questionSetIndex < questionData?.subQuestions?.length - 1
            ) {
              setQuestionSetIndex(() => {
                return questionSetIndex + 1;
              });
              getSectionWiseData(currentSectionId);
            } else {
              setQuestionSetIndex(0);
              if (nextSection) {
                getSectionWiseData(nextSection);
              }
            }
          }
        })
        .catch((e) => {
          setNextLoader(false);
          toast.error("Error While Answering Questions", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handleNext = (action) => {
    setImagesArray([]);
    if (action === "finish" && viewResults) {
      setViewResults(false);
      setQuestionSetIndex(0);
    }

    if (role === "admin") {
      if (preview) {
        if (viewResults) {
          getSectionDetailsWithoutSaving(action);
        } else {
          if (action === "nextSection" || action === "pagination") {
            getSectionDetailsWithoutSaving(action);
          } else {
            getSectionDetailsAfterSaving(action);
          }
        }
      } else {
        getSectionDetailsWithoutSaving(action);
      }
    } else {
      if (action === "pagination" || viewResults) {
        getSectionDetailsWithoutSaving(action);
      } else {
        getSectionDetailsAfterSaving(action);
      }
    }
  };

  const handlePreviousQuestionSet = () => {
    if (questionSetIndex > 0) {
      setQuestionSetIndex(questionSetIndex - 1);
    }
  };

  const handleNextExercise = () => {
    if (exercise?.nextExercise) {
      window.open(exercise.nextExercise, "_self");
      window.location.reload();
    }
  };

  const handleSave = () => {
    handleNext("saveProgress");
  };

  const tooltipProps = {
    title:
      iconDisabled && role !== "admin"
        ? "Please answer question and click next to proceed"
        : "",
    arrow: true,
  };

  const handleOpenEnded = (state) => {
    setOpenEnded(state);
  };

  const handlePreview = () => {
    getSections(null, 0, 1);
    setPreview(true);
    setPreviewMode("1");
  };

  const closePreview = () => {
    getSections();
    setIsExerciseCompleted(false);
    setPreview(false);
    setViewResults(false);
    setPreviewMode("0");
  };

  return (
    <div className={classes.exerciseV2Container}>
      {loading ? (
        <Loader />
      ) : (
        <Box className={classes.root}>
          {role !== "admin" && sectionNo === 1 && (
            <ExerciseOverview
              open={exerciseOverview}
              setExerciseOverview={setExerciseOverview}
              exersice={exercise}
              showBrief={false}
              startTime={startTime}
              setStartTime={setStartTime}
              exerciseId={exerciseId}
              catalogId={catalogId}
              mathWorksheet={0}
              showTimer={showTimer}
              setAttemptId={setAttemptId}
              setStartAssessmentTime={setStartAssessmentTime}
              startAssessmentTime={startAssessmentTime}
              timerDuration={timerDuration}
              setShowAssessmentTimer={setShowAssessmentTimer}
              endAssessmentTime={endAssessmentTime}
              isExerciseCompleted={exercise?.isExerciseCompleted}
              sectionId={sections[sectionNo - 1]?.sectionId}
            />
          )}
          {role === "admin" && preview && (
            <Preview
              open={preview}
              preview={preview}
              closePreview={closePreview}
              role={role}
              isExerciseCompleted={isExerciseCompleted}
              showTimer={showTimer}
              timerDuration={timerDuration}
              startTime={startTime}
              endTime={endTime}
              openEnded={openEnded}
              handleSave={handleSave}
              questionData={questionData}
              viewResults={viewResults}
              sectionNo={sectionNo}
              handleNext={handleNext}
              handleAnswers={handleAnswers}
              setHasAnswered={setHasAnswered}
              questionSetIndex={questionSetIndex}
              handlePreviousQuestionSet={handlePreviousQuestionSet}
              nextSection={nextSection}
              setIconDisabled={setIconDisabled}
              nextLoader={nextLoader}
              handleOpenEnded={handleOpenEnded}
              iconDisabled={iconDisabled}
              prevSection={prevSection}
              handlePrevious={handlePrevious}
              sections={sections}
              sectionLevelData={sectionLevelData}
              handleNextExercise={handleNextExercise}
              exercise={exercise}
              handleReattempt={handleReattempt}
              handleViewResults={handleViewResults}
              completedTime={completedTime}
            />
          )}
          <Grid
            className={`${classes.flex_row} ${classes.justifyBetween} ${classes.alignItemsCenter} ${classes.headerActions}`}
          >
            <Button
              onClick={() => history.goBack()}
              className={`${classes.goBackBtn}`}
            >
              {" "}
              <KeyboardBackspaceOutlinedIcon
                fontSize="small"
                className="mr-1"
              />{" "}
              Go back
            </Button>
            {role !== "admin" && !isExerciseCompleted ? (
              <Grid
                className={`${classes.flex_row} ${classes.alignItemsCenter}`}
              >
                {(getRole() === "student" || getRole() === "guest") &&
                  showTimer &&
                  !timerDuration && (
                    <div className={classes.mobileTimer}>
                      <Timer
                        startTime={startTime}
                        endTime={endTime}
                        type="questionnaire"
                      />
                    </div>
                  )}

                {/* {(getRole() == "student" || getRole() == "guest") &&
                  showAssessmentTimer &&
                  timerDuration && (
                    <AssessmentTimer
                      startTime={startAssessmentTime}
                      endTime={endAssessmentTime}
                      duration={timerDuration}
                      restartTimer={restartTimer}
                      type="Questionnaire"
                    />
                  )} */}
              </Grid>
            ) : (
              role === "admin" && (
                <Grid
                  className={`${classes.qNoBox} ${classes.flex_row} ${classes.alignItemsCenter} p-1`}
                >
                  Total Score: {sectionLevelData?.score || 0}
                </Grid>
              )
            )}
            {role !== "admin" && !isExerciseCompleted && openEnded && (
              <Grid className={classes.saveWrapper}>
                <div className={classes.saveIcon} onClick={handleSave}>
                  Save
                </div>
                <IconButton
                  onClick={handleSave}
                  style={{
                    color: "#FF712D",
                    padding: "0px",
                  }}
                >
                  <SaveIcon style={{ fontSize: "20px" }} />
                </IconButton>
              </Grid>
            )}
            {role !== "admin" &&
              viewResults &&
              questionData?.questionType !== "Question Set" && (
                <Grid className={classes.insightsScores}>
                  Score :{" "}
                  <span
                    className={classes.actualScore}
                  >{`${questionData?.scored} / ${questionData?.score}`}</span>
                </Grid>
              )}
            {role === "admin" && openMoveSectionDialog && (
              <MoveSectionDialog
                openMoveSectionDialog={openMoveSectionDialog}
                setOpenMoveSectionDialog={setOpenMoveSectionDialog}
                sectionNo={sectionNo}
                totalSections={sections?.length || 0}
                exerciseId={exerciseId}
                sectionId={currentSectionId}
                getSections={getSections}
              />
            )}
            {role === "admin" && (
              <Grid>
                <Button
                  onClick={() => setOpenMoveSectionDialog(true)}
                  className={`${classes.goBackBtn}`}
                  style={{ color: "#FF712D" }}
                >
                  Move section
                  <FlipToFrontOutlinedIcon
                    className="ml-1"
                    style={{ fontSize: 16 }}
                  />
                </Button>
                <Button
                  onClick={addSectionHandler}
                  className={`${classes.goBackBtn}`}
                  style={{ color: "#FF712D" }}
                >
                  Add Section +
                </Button>
                <Button
                  onClick={handlePreview}
                  className={`${classes.goBackBtn}`}
                  style={{ color: "#FF712D" }}
                >
                  Preview
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid
            className={`${classes.innerRoot} ${
              isExerciseCompleted && !viewResults && classes.completedContainer
            }`}
          >
            {role === "admin" ? (
              <Grid className={`${classes.adminBox}`}>
                {sections?.length === 0 ? (
                  <Grid className={`${classes.addSectionBtnContainer}`}>
                    <Button
                      onClick={addSectionHandler}
                      className={`${classes.addSectionBtn}`}
                    >
                      Add Section +
                    </Button>
                  </Grid>
                ) : !questionData &&
                  !stickyType &&
                  !textEditor &&
                  !videoEditor &&
                  !singleQuestion ? (
                  <Grid className={`${classes.addSectionBtnContainer}`}>
                    <Button
                      onClick={() => {
                        setAddQustionTypeModal(true);
                      }}
                      className={`${classes.addSectionBtn}`}
                    >
                      Add Question +
                    </Button>
                  </Grid>
                ) : (
                  <React.Fragment>
                    <Grid style={{ height: "100%" }}>
                      <div key={sectionNo} style={{ height: "100%" }}>
                        {stickyType ? (
                          <Box style={{ height: "100%" }}>
                            <InstructionType
                              handleOpenEnded={handleOpenEnded}
                              preview={preview}
                              questionData={questionData}
                              setQuestionData={setQuestionData}
                              setImagesArray={setImagesArray}
                              imagesArray={imagesArray}
                              handleAnswers={handleAnswers}
                              setHasAnswered={setHasAnswered}
                              setIconDisabled={setIconDisabled}
                              clearAdminEditors={clearAdminEditors}
                              getSections={getSections}
                              nextSection={nextSection || prevSection}
                              sectionId={currentSectionId}
                              parentQuestionId={parentQuestionId}
                              getSectionWiseData={getSectionWiseData}
                              currentAdminSubQuestionNo={
                                currentAdminSubQuestionNo
                              }
                              setCurrentAdminSubQuestionNo={
                                setCurrentAdminSubQuestionNo
                              }
                              newSubQuestion={handleNewSubQuestion}
                              isNewQuestion={isNewQuestion}
                              setIsNewQuestion={setIsNewQuestion}
                            />
                          </Box>
                        ) : textEditor ? (
                          <Box style={{ height: "100%" }}>
                            <Instructions
                              questionData={questionData}
                              sectionId={currentSectionId}
                              handleAnswers={handleAnswers}
                              setHasAnswered={setHasAnswered}
                              setIconDisabled={setIconDisabled}
                              clearAdminEditors={clearAdminEditors}
                              getSections={getSections}
                              nextSection={nextSection || prevSection}
                              handleOpenEnded={handleOpenEnded}
                              preview={preview}
                            />
                          </Box>
                        ) : videoEditor ? (
                          <Box style={{ height: "100%" }}>
                            <Video
                              handleOpenEnded={handleOpenEnded}
                              preview={preview}
                              questionData={questionData}
                              handleAnswers={handleAnswers}
                              setHasAnswered={setHasAnswered}
                              setIconDisabled={setIconDisabled}
                              sectionId={currentSectionId}
                              clearAdminEditors={clearAdminEditors}
                              getSections={getSections}
                              nextSection={nextSection || prevSection}
                            />
                          </Box>
                        ) : (
                          singleQuestion && (
                            <Box style={{ height: "auto", padding: "12px" }}>
                              <AdminQuestions
                                setQuestionData={setQuestionData}
                                questionData={questionData}
                                setImagesArray={setImagesArray}
                                imagesArray={imagesArray}
                                sectionId={currentSectionId}
                                clearAdminEditors={clearAdminEditors}
                                getSections={getSections}
                                nextSection={nextSection || prevSection}
                                getSectionWiseData={getSectionWiseData}
                                isNewQuestion={isNewQuestion}
                                setIsNewQuestion={setIsNewQuestion}
                              />
                            </Box>
                          )
                        )}
                      </div>
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            ) : isExerciseCompleted && !viewResults ? (
              <SectionSuccessPage
                sectionLevelData={sectionLevelData}
                handleNextExercise={handleNextExercise}
                exercise={exercise}
                handleReattempt={handleReattempt}
                handleViewResults={handleViewResults}
                completedTime={completedTime}
              />
            ) : (
              <Box
                className={`${classes.sectionsWrapper} ${
                  questionData?.questionType === "Question Set" &&
                  classes.fullHeight
                }`}
              >
                <Box
                  className={`${
                    questionData?.questionType === "Question Set"
                      ? classes.sectionsInstructionType
                      : classes.sectionsContainer
                  } ${
                    questionData?.questionType === "Question Set" &&
                    classes.fullHeight
                  }`}
                >
                  <Grid
                    className={
                      questionData?.questionType === "Question Set" &&
                      classes.fullHeight
                    }
                  >
                    {questionData && (
                      <div key={sectionNo} className={classes.fullHeight}>
                        {questionData?.questionType === "Question Set" ? (
                          <Box className={classes.fullHeight}>
                            <InstructionType
                              handleOpenEnded={handleOpenEnded}
                              preview={preview}
                              questionData={questionData}
                              setQuestionData={setQuestionData}
                              handleNextSection={handleNext}
                              handleAnswers={handleAnswers}
                              setHasAnswered={setHasAnswered}
                              questionSetIndex={questionSetIndex}
                              handlePreviousQuestionSet={
                                handlePreviousQuestionSet
                              }
                              nextSection={nextSection}
                              viewResults={viewResults}
                              setIconDisabled={setIconDisabled}
                              nextLoader={nextLoader}
                              isExerciseCompleted={isExerciseCompleted}
                              newSubQuestion={handleNewSubQuestion}
                              isNewQuestion={isNewQuestion}
                              setIsNewQuestion={setIsNewQuestion}
                            />
                          </Box>
                        ) : questionData?.questionType ===
                          "Instruction - Text" ? (
                          <Box>
                            <Instructions
                              questionData={questionData}
                              handleAnswers={handleAnswers}
                              setHasAnswered={setHasAnswered}
                              setIconDisabled={setIconDisabled}
                              handleOpenEnded={handleOpenEnded}
                            />
                          </Box>
                        ) : questionData?.questionType ===
                          "Instruction - Video" ? (
                          <Box>
                            <Video
                              handleOpenEnded={handleOpenEnded}
                              preview={preview}
                              questionData={questionData}
                              handleAnswers={handleAnswers}
                              setHasAnswered={setHasAnswered}
                              setIconDisabled={setIconDisabled}
                            />
                          </Box>
                        ) : (
                          <Box>
                            <Questions
                              data={questionData}
                              handleNextSection={handleNext}
                              handleAnswers={handleAnswers}
                              setHasAnswered={setHasAnswered}
                              questionSetIndex={questionSetIndex}
                              handlePreviousQuestionSet={
                                handlePreviousQuestionSet
                              }
                              nextSection={nextSection}
                              viewResults={viewResults}
                              setIconDisabled={setIconDisabled}
                              nextLoader={nextLoader}
                              isExerciseCompleted={isExerciseCompleted}
                              handleOpenEnded={handleOpenEnded}
                            />
                          </Box>
                        )}
                      </div>
                    )}
                  </Grid>
                  {questionData?.questionType !== "Question Set" && (
                    <Box
                      className={`${classes.actionsContainer} ${classes.justifyCenter}`}
                    >
                      {nextSection ? (
                        <Button
                          onClick={() => handleNext("next")}
                          className={classes.actionNextBtn}
                          endIcon={<NextIcon className={classes.nextIcon} />}
                        >
                          Next
                        </Button>
                      ) : (
                        <Button
                          onClick={() => handleNext("finish")}
                          className={classes.actionNextBtn}
                        >
                          Finish
                        </Button>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Grid>
          {(!isExerciseCompleted || (isExerciseCompleted && viewResults)) && (
            <Box className={classes.sectionCountContainer}>
              <Box
                className={`${classes.qNoBox} ${classes.sectionCountWrapper} ${classes.width100}`}
              >
                {prevSection && (
                  <Box>
                    <IconButton
                      onClick={handlePrevious}
                      style={{
                        color: "#2F4F4F",
                        padding: "0px",
                        marginRight: "20px",
                      }}
                    >
                      <BackIcon style={{ fontSize: "20px" }} />
                    </IconButton>
                  </Box>
                )}
                <Box
                  className={` ${classes.flex_row} ${classes.alignItemsCenter} ${classes.sectionCountText}`}
                >
                  Section {sectionNo} of {sections?.length}
                </Box>
                {(questionData?.questionType !== "Question Set" ||
                  role === "admin" ||
                  viewResults) &&
                  nextSection && (
                    <Tooltip {...tooltipProps}>
                      <Box>
                        <IconButton
                          onClick={() =>
                            handleNext(
                              role === "admin" || viewResults
                                ? "nextSection"
                                : "pagination"
                            )
                          }
                          style={{
                            color:
                              iconDisabled && role !== "admin"
                                ? "#b6cfcf"
                                : "#2F4F4F",
                            padding: "0px",
                            marginLeft: "20px",
                          }}
                          disabled={iconDisabled && role !== "admin"}
                        >
                          <NextIcon />
                        </IconButton>
                      </Box>
                    </Tooltip>
                  )}
              </Box>
            </Box>
          )}
          {addQustionTypeModal && (
            <AddExercise
              open={addQustionTypeModal}
              close={closeModalHandler}
              setStickyType={setStickyType}
              setTextEditor={setTextEditor}
              setVideoEditor={setVideoEditor}
              setSingleQuestion={setSingleQuestion}
              setParentQuestionId={setParentQuestionId}
              exerciseId={exerciseId}
              sectionId={currentSectionId}
              getSectionWiseData={getSectionWiseData}
            />
          )}
        </Box>
      )}
    </div>
  );
};

export default Index;
