import { decrypt } from "../../../Core/utils/others";
import LearningCreationPopup from "./LearningCreationPopup";

const AppPathCreationContainer = () => {
  const returnToApp = () => {
    const returnUrl = localStorage.getItem("appReturnUrl");
    let linkedUser = localStorage.getItem("linkedUser");
    if (linkedUser) {
      linkedUser = decrypt(linkedUser);
    }

    const mobileData = {
      type: "route",
      url: returnUrl,
      params: { studentId: linkedUser?.userId },
    };
    window.ReactNativeWebView.postMessage(JSON.stringify(mobileData));
  };
  return (
    <div>
      <LearningCreationPopup
        open={true}
        closeModal={returnToApp}
        isNewUser={false}
        location="app"
      />
    </div>
  );
};

export default AppPathCreationContainer;
