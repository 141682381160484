import Cookies from "js-cookie";
import { decrypt } from "../../../../Core/utils/others";
import { getRole } from "../../../../Core/utils/auth";
import jwt_decode from "jwt-decode";

export const navigateToQuiz = (userData) => {
  const role = getRole();
  const sandbox_user_data = decrypt(localStorage.getItem("sandbox_user_data"));
  const accesToken = decrypt(localStorage.getItem("sandbox_user"));
  const userInfo = jwt_decode(accesToken.ACCESS_TOKEN).userInfo;
  const userId = sandbox_user_data?.studentId || userInfo.userId;
  const allowedUsers = process.env?.REACT_APP_ALLOWED_USERS?.split(",") || [];
  const language = userData?.subject?.toLowerCase();
  
  const userName = sandbox_user_data?.studentName || accesToken.userName;
  Cookies.set("userName", userName, {
    path: "/",
    domain: ".codeyoung.com",
  });
  if (role != "guest") {
    Cookies.set("grade", sandbox_user_data?.grade, {
      path: "/",
    });
  }
  Cookies.set("userId", userId, {
    path: "/",
  });
  Cookies.set("userRole", role, {
    path: "/",
  });

  const url = process.env?.REACT_APP_AI_QUIZ_URL_DEV;

  if (userData?.type == "language") {
    window.location.href = `${url}languages?lang=${language}&from=sandbox`;
  } else {
    window.open(`${url}`);
  }
};
