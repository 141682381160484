import axios from "axios";

export const getIPInfo = async () => {
  try {
    const ipResponse = await axios.get("https://api.ipify.org?format=json");
    const ip = ipResponse?.data?.ip;

    const detailsResponse = await axios.get(
      `https://api.ipapi.com/${ip}?access_key=8a6e6544ec0ff1478e9cf54138dfb1ed`
    );
    const details = detailsResponse.data;

    return {
      ip,
      timezone: details.time_zone,
      country: details.country_name,
      region: details.region_name,
      city: details.city,
      latitude: details.latitude,
      longitude: details.longitude,
      countryCode: details.country_code
    };
  } catch (error) {
    throw new Error("Failed to fetch IP information");
  }
};
